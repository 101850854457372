import ExtjsStateManager from "../extjs-state-manager";

const DEFAULT_ATS_BASE = "paris";

export const getSessionStorage = () => {
  const storedSession = ExtjsStateManager.get("ext-session");
  return {
    atsBase: storedSession?.ats?.ats_base || DEFAULT_ATS_BASE,
    token: storedSession?.tokenApi?.token,
    tokenMedia: storedSession?.tokenMedia?.token,
  };
};

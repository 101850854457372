import "react-bootstrap-typeahead/css/Typeahead.css";
import "./i18n";
import "./assets/styles/index.scss";
import "bootstrap/dist/js/bootstrap.bundle";
import React, { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as SentryReact from "@sentry/react";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";

import App from "App";
import { ErrorFallback } from "components/ErrorFallback/ErrorFallback";
import { getStage, STAGES } from "utils/config";

import { SessionContextProvider } from "./store/session-context";
import { store } from "./store/redux/store";
import { appTheme } from "./theme";
import "react-toastify/dist/ReactToastify.css";

// Makes week start with Monday on date picker MUI
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

const tracingOrigins = [
  "ama.lincoln-group.com",
  "ama-dev.lincoln-group.com",
  "ama-preprod.lincoln-group.com",
];

let SENTRY_DSN = null;
const environment = getStage();

if (environment !== STAGES.LOCAL && environment !== STAGES.DEV) {
  SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";
}

SentryReact.init({
  dsn: SENTRY_DSN,
  environment,
  integrations: [
    new SentryReact.BrowserProfilingIntegration(),
    new SentryReact.BrowserTracing({
      routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new SentryReact.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracingOrigins,
  tracePropagationTargets: tracingOrigins,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  _experiments: {
    enableInteractions: true,
    onStartRouteTransaction: SentryReact.onProfilingStartRouteTransaction,
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <SessionContextProvider>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SentryReact.ErrorBoundary FallbackComponent={ErrorFallback}>
              <App />
            </SentryReact.ErrorBoundary>
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </SessionContextProvider>
  </StrictMode>,
);

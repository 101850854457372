import { createSlice } from "@reduxjs/toolkit";

const matcheInitialState = {
  profilQueue: [],
};

export const matcheSlice = createSlice({
  name: "matcheSlice",
  initialState: matcheInitialState,
  reducers: {
    addReference: (state, action) => {
      state.candidats = action.payload;
      return state;
    },
  },
});

export const { addReference } = matcheSlice.actions;

export const FEES_TYPES = [
  { id: 1, name: "Fixed amount", label: "Fixed-price assignment" },
  { id: 2, name: "Percentage", label: "Percentage-based assignment" },
];


export const RESULT_DECLINED = [24, 25, 106];

export const RESULT_DECLINED_NAME = ["Lincoln declined", "Client declined"];

export const ASSIGNMENT_TYPES = {
  EXECUTIVE_SEARCH: "executive_search",
  INTERIM_MANAGEMENT: "interim_management",
  LEADERSHIP_ADVISORY: "leadership_advisory",
  UNKNOWN: "unknown",
};

export const ASSIGNMENT_STATUS = {
  PROSPECTING: 0,
  IN_PROGRESS: 1,
  CLOSED: 2,
  LOST: 3,
  INTERRUPTED: 4,
};

export const ASSIGNMENT_KANBAN_COLUMNS = [
  {
    id: "identified",
    title: "Identified",
  },
  {
    id: "qualified",
    title: "Qualified",
  },
  {
    id: "lincoln_interview",
    title: "Lincoln Interview",
  },
  {
    id: "short_listed",
    title: "Short Listed",
  },
  {
    id: "client_interview",
    title: "Client Interview",
  },
  {
    id: "in_offer",
    title: "In Offer",
  },
  {
    id: "hired",
    title: "Recruited",
  },
];

export const KANBAN_COLUMN_STATES = {
  QUALIFIED: "qualified",
  SHORT_LISTED: "short_listed",
  LINCOLN_INTERVIEW: "lincoln_interview",
  IN_OFFER: "in_offer",
  CLIENT_INTERVIEW: "client_interview",
  RECRUITED: "hired",
  IDENTIFIED: "identified",
};

import { createApi } from "@reduxjs/toolkit/query/react";

import { config } from "utils/config";

import { axiosBaseQuery } from "../BaseQuery/axiosBaseQuery";

export const api = createApi({
  baseQuery: axiosBaseQuery({
    mediaUrl: config.mediaUrl,
  }),
  endpoints: () => ({}),
  tagTypes: [
    "phoneCallResult",
    "TalentAction",
    "Consultant",
    "TalentFiles",
    "Researcher",
    "TalentItem",
    "KanbanAssignmentBoard",
    "AssignmentActions",
    "TalentByAtsId",
    "Currency",
    "Languages",
    "AreaOfExpertise",
    "Educations",
    "Mobility",
    "BusinessUnit",
    "JobTitles",
    "DICT",
    "Functions",
    "UserByType",
    "ConsultantCandidate",
    "GetConsultants",
    "CurrentUserProfil",
    "AssignmentTypes",
    "Term",
    "GetProfileData",
  ],
});

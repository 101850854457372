import _ from "lodash";

export const RETAINED_RECRUITMENT_INTERVIEW_TYPE = {
  VISIOCONFERENCE: 2,
  VISIOCONFERENCE_WITH_ROOM: 1,
  FACE_TO_FACE: 0,
};

export const LTI_OPTIONS = [
  { id: "fix", label: "Interest / participation amount" },
  { id: "percent", label: "Interest / participation percentage" },
];

export const FILTER_ACTION_ADD_TALENT = [
  "CM - AR Sent",
  "CM - Claims",
  "CM - Consultant reminder",
  "CM - Follow up call/written/email",
  "CM - Formal notice",
  "CM - Promise of payment",
  "CM - Reminder with AR",
  "CM - Reply from customer accountant",
  "CM - Sales reminder",
  "CM- GNI sent",
  "CM- Ligitation case",
  "CM- To send to lawyer",
  "CM-Duplicata sent",
  "CM-Follow up 1",
  "Invoices received check",
  "MCPH - Questionnaire sent",
  "MCPH Debriefing",
  "Publish on Lincoln Connect",
  "Sourcing Poland",
  "Company reception call",
];

// Proxy Object To Plain Object
export const convertProxyObjectToPojo = (proxyObj) => {
  return _.cloneDeep(proxyObj);
};

export const UPLOAD_TYPE = {
  ASSIGNMENT: "assignment",
  TALENT: "talent",
  NDA: "nda",
  CV: "cv",
};

export const PERIMETER_VALUE = {
  global: "global",
  partial: "partial",
};

export const GROUP_LOCATION = {
  // Europe
  1001: [1, 5, 3, 4, 9, 10, 11],
  // Asia
  1002: [7, 6, 2],
  // France
  2001: [5, 3, 4],
};

export const HTTP_METHODS = {
  GET: "GET",
  DELETE: "DELETE",
  PUT: "PUT",
  POST: "POST",
};

export const DEFAULT_STATUS_OPTION = [
  { name: "Prospecting", value: 0, id: 0, label: "Prospecting" },
  { name: "In progress", value: 1, id: 1, label: "In progress" },
  { name: "Closed", value: 2, id: 2, label: "Closed" },
  { name: "Lost", value: 3, id: 3, label: "Lost" },
  { name: "Interrupted", value: 4, id: 4, label: "Interrupted" },
];
export const REQUIRED_ERROR_MESSAGE = "This field is required";

export const MAX_CHAR_STRENGTH = 2200;
export const MAX_CHAR_ATTENTION = 600;
export const MAX_CHAR_PROJECT = 450;
export const ASSESSMENT_STRENGTH_MAX_LINE = 20;
export const ASSESSMENT_ATTENTION_MAX_LINE = 5;
export const ASSESSMENT_MOTIVATION_MAX_LINE = 9;

export const ASSIGNMENT_STATUS = {
  PROSPECTING: 0,
  IN_PROGRESS: 1,
  CLOSED: 2,
  LOST: 3,
  INTERRUPTED: 4,
};

export const RETAINED_RECRUITMENT_ONBOARDING_ACTION_TYPES = {
  POINT_ONBOARDING: "Point onboarding",
  BUSINESS_DEVELOPMENT: "Business development",
  WEEKLY_UPDATE: "Weekly meeting",
  PIPE: "Pipe",
  DOCUMENT: "Document",
};

export const RETAINED_RECRUITMENT_DOCUMENT_TYPES = {
  CONTRACT: "Contract",
  SALES_PROPOSAL: "Sales proposal",
  PROGRESS_REPORT: "Progress report",
  JOB_DESCRIPTION: "Job description",
};

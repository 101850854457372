import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerTitle: {
    managerName: "",
    name: "",
  },
};

export const followUpSlice = createSlice({
  name: "followUp",
  initialState,
  reducers: {
    setHeaderTitle: (state, action) => {
      state.headerTitle = { ...state.headerTitle, ...action.payload };
      return state;
    },
  },
});
export const header = (state) => state.followUp.headerTitle;
export const { setHeaderTitle } = followUpSlice.actions;

import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import {
  AssignmentCreate,
  AssignmentGenerateTalentDescription,
  AssignmentManagement,
  Assignments,
  AssignmentTimeline,
} from "routes/pages.import";

export const assignment = [
  {
    path: "/assignments",
    element: (
      <ProtectedRoute layoutType="ASSIGNMENT_MANAGEMENT">
        <Box sx={{ height: "100%" }} aria-label="assignment-outlet">
          <Outlet />
        </Box>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Assignments />,
      },
      {
        path: "create",
        element: <AssignmentCreate />,
      },
      {
        path: "manage/:id",
        element: <AssignmentManagement />,
      },
      {
        path: "generate-job-description/:id",
        element: <AssignmentGenerateTalentDescription />,
      },
      {
        path: "generate-job-description/:ats_base/:ats_assignment_id",
        element: <AssignmentGenerateTalentDescription />,
      },
      {
        path: "timelines",
        element: <AssignmentTimeline />,
      },
    ],
  },
];

import * as Sentry from "@sentry/react";

import { SENTRY_URL_TAGS_BY_MODULE } from "utils/constant/sentry/module";

const getModuleTagForSentry = () => {
  const { pathname } = window.location;

  if (pathname.includes("assignments/manage"))
    return SENTRY_URL_TAGS_BY_MODULE.ASSIGNMENT_MANAGEMENT;

  if (pathname.includes("assignments/create"))
    return SENTRY_URL_TAGS_BY_MODULE.ASSIGNMENT_CREATION;

  if (pathname.includes("directory"))
    return SENTRY_URL_TAGS_BY_MODULE.DIRECTORY;

  if (pathname.includes("document")) return SENTRY_URL_TAGS_BY_MODULE.DOCUMENT;

  if (pathname.includes("company")) return SENTRY_URL_TAGS_BY_MODULE.COMPANY;

  if (pathname.includes("matche")) return SENTRY_URL_TAGS_BY_MODULE.MATCHE;

  if (pathname.includes("assignments/generate-job-description")) {
    return SENTRY_URL_TAGS_BY_MODULE.TALENT_DESCRIPTION;
  }

  return SENTRY_URL_TAGS_BY_MODULE.NOT_SPECIFIED;
};

export const sendFeedbackToSentry = ({ session, error, data }) => {
  const moduleName = getModuleTagForSentry();

  const sentryErrorMessage = `Module ${moduleName}: ${
    error.response?.data?.message || "Error while making request"
  }`;

  Sentry.setUser({
    id: session.user.id,
    email: session.user.email,
    name: `${session.user.firstname} ${session.user.lastname}`,
  });

  Sentry.setTag("module", moduleName);

  Sentry.addBreadcrumb({
    level: "info",
    message: "payload",
    data: { ...data },
  });

  Sentry.captureMessage(sentryErrorMessage);
  Sentry.captureException(error);
};

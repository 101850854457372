import { createSlice } from "@reduxjs/toolkit";

const assignmentInitialState = {
  reference: "N/C",
  filters: {
    reference: null,
    jobtitle: null,
    company: null,
    status: "",
    consultant: null,
  },
  currentTab: "1",
};

export const assignmentSlice = createSlice({
  name: "assignmentSlice",
  initialState: assignmentInitialState,
  reducers: {
    addReference: (state, action) => {
      state.reference = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    resetReference: () => assignmentInitialState,
  },
});

export const { addReference, resetReference, setFilters, setCurrentTab } =
  assignmentSlice.actions;

export const assignmentReference = (state) => state.assignment.reference;
export const filters = (state) => state.assignment.filters;
export const currentTab = (state) => state.assignment.currentTab;

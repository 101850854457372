import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const styles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const ErrorFallback = () => {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <Box sx={{ ...styles, rowGap: 2 }}>
      <ReportProblemIcon
        sx={{
          fontSize: 150,
          color: ({ palette }) => palette.red.main,
        }}
      />
      <Typography variant="h6">
        Something went wrong, Please contact the developer team!!!
      </Typography>
      <Button color="red" variant="contained" onClick={goToHomePage} data-testid="return-button">
        Go Back to the Home page
      </Button>
    </Box>
  );
};

import { createSlice } from "@reduxjs/toolkit";

// const initialState = { message: undefined, errorId: undefined };

const initialState = {
  netWorkError: {
    message: undefined,
  },
  randomError: {
    message: undefined,
    errorId: undefined,
  },
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    showError: (state, action) => {
      state.randomError.message = JSON.stringify(action.payload.message);
      state.randomError.errorId = action.payload.errorId;
      return state;
    },
    hideError: (state) => {
      state.message = undefined;
      return state;
    },
    showNetworkError: (state, action) => {
      state.netWorkError.message = JSON.stringify(action.payload.message);
      return state;
    },
  },
});
export const { showError, hideError, showNetworkError } = errorSlice.actions;
export default errorSlice.reducer;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

import Logo from "../Logo/Logo";
import { LinkonLogo } from "../Icons/Logo/LinkonLogo";
import { toogleSideBar } from "../../../store/redux/slice/layout/layoutSlice";
import { FoldableOpenIcon } from "../Icons/FoldableOpenIcon/FoldableOpenIcon";
import { FoldableCloseIcon } from "../Icons/FoldableCloseIcon/FoldableCloseIcon";

import { NavItemLink } from "./NavItemLink";
import { NavItems } from "./NavItems";
import styles from "./SideBarNav.module.scss";

const SideBarNav = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const toggleOpen = () => {
    dispatch(toogleSideBar());
    setOpen(!open);
  };

  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      <Box padding={0} margin={0} height={130}>
        <Box position="absolute">
          <Box
            component="span"
            position="absolute"
            top={-15}
            left={155}
            className={open ? styles.logoLincoln : styles.logoLincolnTranslate}
          >
            <LinkonLogo />
          </Box>
        </Box>
        <Logo
          className={`${styles.logoSeparator} mt-3 position-relative`}
          open={open}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        {NavItems.map((item) => (
          <NavItemLink open={open} item={item} key={item.id} />
        ))}
        <button className={styles.menuBtn} onClick={toggleOpen} type="button">
          <Box width={25} height={25}>
            {open ? <FoldableOpenIcon /> : <FoldableCloseIcon />}
          </Box>
          <Box component="span" className={styles.linkTextFoldable}>
            Fold Menu
          </Box>
        </button>
      </Box>
    </div>
  );
};

export default SideBarNav;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { api } from "./api/common/api/api";
import { assignmentSlice } from "./slice/assignment/assignmentSlice";
import { userSlice } from "./slice/user/userSlice";
import { matcheSlice } from "./slice/matche/matcheSlice";
import { layoutSlice } from "./slice/layout/layoutSlice";
import { errorSlice } from "./slice/errorSlice/errorSlice";
import { kanbanSlice } from "./slice/kanban/kanbanSlice";
import { followUpSlice } from "./slice/followUp/followUpSlice";
import { chatApi } from "./api/common/api/chatApi";
import { notificationApi } from "./api/common/api/notificationApi";
import { talentSlice } from "./slice/TalentSlice/TalentSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    assignment: assignmentSlice.reducer,
    matche: matcheSlice.reducer,
    layout: layoutSlice.reducer,
    error: errorSlice.reducer,
    user: userSlice.reducer,
    kanban: kanbanSlice.reducer,
    followUp: followUpSlice.reducer,
    talent: talentSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware)
      .concat(chatApi.middleware)
      .concat(notificationApi.middleware),
});

setupListeners(store.dispatch);

import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
  profilBlobUrl: "",
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState: userInitialState,
  reducers: {
    setProfilBlobUrl: (state, action) => {
      state.profilBlobUrl = action.payload;
      return state;
    },
  },
});

export const { setProfilBlobUrl } = userSlice.actions;

import ExtjsStateManager from "./extjs-state-manager";

const retrieveStoredSession = () => {
  // TODO: à remplacer lorsque la compatibilité avec le gestionnaire de session de ExtJS ne sera plus nécéssaire
  const storedSession = ExtjsStateManager.get("ext-session");
  return storedSession;
};

export const config = {
  token: retrieveStoredSession(),
  baseUrl: process.env.REACT_APP_API_URL || "",
};

export const STAGES = {
  DEV: "DEV",
  PROD: "PROD",
  LOCAL: "LOCAL",
  PRE_PROD: "PRE_PROD",
};

export const API_BASE = JSON.parse(process.env.REACT_APP_API_BASE || "[]");

export function getDefaultApiBase() {
  const defaultBase = API_BASE.find((api) => api?.label === "Europe");
  const apiBase = ExtjsStateManager.get("ext-api-base");

  if (!apiBase && defaultBase) {
    ExtjsStateManager.set("ext-api-base", defaultBase.label);
  }

  return apiBase;
}

function getApi(apiBase = "") {
  const defaultApiBase = apiBase || getDefaultApiBase();
  const api = API_BASE.find((api) => api?.label === defaultApiBase);
  return api;
}

/**
 * getApiUrl
 *
 * You can use this function to retrieve the API base URL.
 * The URL obtained depends on the selected country (Europe or Asia).
 *
 * - Use this function inside utility functions.
 * - If you need to call this inside a component or custom hooks,
 *   please import and use `getApiUrl` from `store/session-context.js`.
 *
 * @returns string
 */
export function getApiUrl(apiBase = "") {
  const api = getApi(apiBase);
  return api?.url || "";
}

/**
 * getMediaUrl
 *
 * Get Media base URL depends on selected country (Europe or Asia)
 * @returns string
 */
export function getMediaUrl(apiBase = "") {
  const api = getApi(apiBase);
  return api?.media_url || "";
}

export function getStage() {
  const location = window.location.hostname;

  if (location.includes("ama-dev")) return STAGES.DEV;
  if (location.includes("ama-preprod")) return STAGES.PRE_PROD;
  if (location.includes("local")) return STAGES.LOCAL;
  return STAGES.PROD;
}

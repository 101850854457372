import { lazy } from "react";

// Login
export const Login = lazy(() => import("pages/LoginPage"));

// Home
export const Home = lazy(() => import("pages/HomePage"));

// Assignment
export const Assignments = lazy(() => import("pages/Assignments/Lists"));
export const AssignmentCreate = lazy(() =>
  import("pages/Assignments/CreateAssignment"),
);
export const AssignmentManagement = lazy(() =>
  import("pages/Assignments/Management"),
);
export const AssignmentGenerateTalentDescription = lazy(() =>
  import("pages/Assignments/JobDescription/JobDescription"),
);
export const AssignmentTimeline = lazy(() =>
  import("pages/Assignments/Timelines"),
);

// Matche
export const Matche = lazy(() => import("pages/Matche/Matche"));

// Statistic Follow Up
export const StatisticFollowUp = lazy(() =>
  import("pages/StatisticFollowUp/StatisticFollowUp"),
);
export const FollowUpMyTeams = lazy(() =>
  import("components/StatisticFollowUp/MyTeams/FollowUpMyTeams"),
);
export const ConsultantStatistic = lazy(() =>
  import(
    "components/StatisticFollowUp/ConsultantStatistic/ConsultantStatistic"
  ),
);
export const TeamStatistic = lazy(() =>
  import("components/StatisticFollowUp/TeamStatistic/TeamStatistic"),
);
export const FollowUp = lazy(() =>
  import("components/StatisticFollowUp/FollowUp/FollowUp"),
);

// Directory
export const Directory = lazy(() => import("pages/Directory/Directory"));

// KPI
export const Kpi = lazy(() => import("pages/Kpi/Kpi"));

// Document
export const Document = lazy(() => import("pages/Document/Document"));

// Company
export const CompanyList = lazy(() => import("pages/Company/List/List"));
export const CompanyDetails = lazy(() => import("pages/Company/Details"));

// Groupe
export const GroupList = lazy(() => import("pages/Group/List"));

// Talent
export const TalentList = lazy(() => import("pages/Talent/List/"));
export const TalentDetails = lazy(() =>
  import("pages/Talent/TalentContentsPage"),
);

// User
export const UserProfile = lazy(() => import("pages/Profile"));

// Notification
export const Notifications = lazy(() => import("pages/Notifications"));

import _ from "lodash";
import { toast } from "react-toastify";

import { DEFAULT_STATUS_OPTION } from "utils/constant";
import {
  ASSIGNMENT_TYPES,
  KANBAN_COLUMN_STATES,
} from "utils/constant/assignment/assignment";
import { EXECUTIVE_SEARCH_IDS } from "utils/constant/assignment/executive-search";
import { LEADERSHIP_ADVISORY_IDS } from "utils/constant/assignment/leadership-advisory";
import { INTERIM_MANAGEMENT_IDS } from "utils/constant/assignment/interim-management";

import { getSessionStorage } from "./session";

const { atsBase } = getSessionStorage();

export function getLatestAssignmentInProgress(assignments = []) {
  let latestAssignmentInProgress;

  const orderedAssignments = _.orderBy(
    assignments,
    [(item) => new Date(item.create_date), (item) => item.ats_assignment_id],
    ["desc", "desc"],
  );

  if (orderedAssignments.length > 0) {
    const [firstItem] = orderedAssignments;
    latestAssignmentInProgress = firstItem;
  }

  return latestAssignmentInProgress;
}

export function getUrlParams(scope, queryParams) {
  const params = new URLSearchParams();

  params.set("ats_base", atsBase);
  params.set("scope", scope);
  params.set("page", queryParams?.page || 1);
  params.set("limit", queryParams?.limit || 100);

  if (queryParams?.globalFilter) {
    params.set("search", queryParams.globalFilter);
  }

  if (queryParams?.user_id) {
    params.set("user_id", queryParams.user_id);
  }

  if (queryParams?.sortDirection) {
    params.set("sort[direction]", queryParams.sortDirection);
  }

  if (queryParams?.sortProperty) {
    params.set("sort[property]", queryParams.sortProperty);
  }

  if (queryParams?.offlimit) {
    params.set("offlimit", queryParams.offlimit);
  }

  if (queryParams?.filter?.length > 0) {
    params.set("filter", JSON.stringify(queryParams.filter));
  }

  if (queryParams?.state) {
    if (Array.isArray(queryParams.state)) {
      queryParams?.state?.forEach((id) => {
        params.append("state[]", id);
      });
    } else {
      params.append("state", queryParams?.state);
    }
  }

  return params;
}

export const reorderArrayPipe = ({ data, source, destination }) => {
  const current = [...data[source.droppableId]];
  const next = [...data[destination.droppableId]];
  const target = current[source.index];

  current.splice(source.index, 1);

  // always drop item on the top (droppedIdIndex,_,_)
  next.splice(0, 0, target);

  return {
    ...data,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };
};

export const dragDirection = ({ from, to }) => `${from} -> ${to}`;

// GIVE A ORDER TO THE KANBAN COLUMN
export const getColumKanbanNum = (id) => {
  switch (id) {
    case KANBAN_COLUMN_STATES.IDENTIFIED:
      return 1;
    case KANBAN_COLUMN_STATES.QUALIFIED:
      return 2;
    case KANBAN_COLUMN_STATES.LINCOLN_INTERVIEW:
      return 3;
    case KANBAN_COLUMN_STATES.SHORT_LISTED:
      return 4;
    case KANBAN_COLUMN_STATES.CLIENT_INTERVIEW:
      return 5;
    case KANBAN_COLUMN_STATES.IN_OFFER:
      return 6;
    case KANBAN_COLUMN_STATES.RECRUITED:
      return 7;
    default:
      return 0;
  }
};

export const showToastOnSavingInProgress = (
  message = "Operation In progress",
) =>
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    progress: undefined,
  });

export const showToastOnError = (
  message = "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
) => {
  toast.error(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};

export const canDownloadContract = (typeId) => {
  if (!typeId) return false;

  return [...EXECUTIVE_SEARCH_IDS].includes(typeId);
};

export function parseStatus(params) {
  try {
    const assignmentStatus = !params.status
      ? [0, 1]
      : !params.status.includes("5")
      ? JSON.parse(params.status)
      : DEFAULT_STATUS_OPTION.map((s) => s.value);

    return assignmentStatus;
  } catch (error) {
    // Return prospecting and in progress in case of error
    return [0, 1];
  }
}

/**
 *
 * @param {number} typeId - Assignment id
 * @returns {string} The assignment type corresponding to the provided type ID.
 *
 *
 */
export function getAssignmentType(typeId) {
  if (LEADERSHIP_ADVISORY_IDS.includes(typeId))
    return ASSIGNMENT_TYPES.LEADERSHIP_ADVISORY;
  if (INTERIM_MANAGEMENT_IDS.includes(typeId))
    return ASSIGNMENT_TYPES.INTERIM_MANAGEMENT;
  if (EXECUTIVE_SEARCH_IDS.includes(typeId))
    return ASSIGNMENT_TYPES.EXECUTIVE_SEARCH;

  return ASSIGNMENT_TYPES.UNKNOWN;
}

/**
 * Retrieves the assignment status based on the given state code.
 *
 * @param {number} state - The state code representing the assignment status.
 * @returns {string} The corresponding assignment status name.
 *   Returns an empty string if the state code does not match any status.
 */
export function getAssignmentStatus(state) {
  const statusMap = {
    0: "Prospecting",
    1: "In progress",
    2: "Closed",
    3: "Lost",
    4: "Interrupted",
  };

  return statusMap[state] || "";
}

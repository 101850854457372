import { Outlet } from "react-router-dom";

import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { GroupList } from "routes/pages.import";

export const group = [
  {
    path: "/group",
    element: (
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <GroupList />,
      },
    ],
  },
];
